import React from "react";

export default function TermsConditions() {
  return (
    <div className="terms-container">
      <h1 className="terms-title">Privacy Policy</h1>
      <p id="effective-date">Effective date: January 19, 2019</p>
      <p className="terms-body-text">
        Flors Carolina ("us", "we", or "our") operates following website and the
        Flors Carolina mobile application (hereinafter referred to as the
        "Service"):
      </p>
      <p className="terms-body-text" id="link">
        <a id="link" href="http://www.florscarolina.com">
          http://www.florscarolina.com{" "}
        </a>
      </p>

      <p className="terms-body-text">
        This page informs you of our policies regarding the collection, use, and
        disclosure of personal data when you use our Service and the choices you
        have associated with that data. The Privacy Policy for Flors Carolina is
        based on the
        <a href="https://termsfeed.com/blog/sample-privacy-policy-template/">
          TermsFeed's Privacy Policy Template
        </a>
        .
      </p>
      <p className="terms-body-text">
        We use your data to provide and improve the Service. By using the
        Service, you agree to the collection and use of information in
        accordance with this policy. Unless otherwise defined in this Privacy
        Policy, the terms used in this Privacy Policy have the same meanings as
        in our Terms and Conditions.
      </p>
      <h2 className="terms-title">Definitions</h2>
      <h3 className="terms-title">Service</h3>
      <p className="terms-body-text">
        Service means the http://www.florscarolina.com website and the Flors
        Carolina mobile application operated by Flors Carolina
      </p>
      <h3 className="terms-title">Personal Data</h3>
      <p className="terms-body-text">
        Personal Data means data about a living individual who can be identified
        from those data (or from those and other information either in our
        possession or likely to come into our possession).
      </p>
      <h3 className="terms-title">Usage Data</h3>
      <p className="terms-body-text">
        Usage Data is data collected automatically either generated by the use
        of the Service or from the Service infrastructure itself (for example,
        the duration of a page visit).
      </p>
      <h3 className="terms-title">Cookies</h3>
      <p className="terms-body-text">
        Cookies are small files stored on your device (computer or mobile
        device).
      </p>
      <h3 className="terms-title">Information Collection and Use </h3>
      <p className="terms-body-text">
        We collect several different types of information for various purposes
        to provide and improve our Service to you.
      </p>
      <h3 className="terms-title">Types of Data Collected </h3>
      <h4 className="terms-title">Personal Data </h4>
      <p className="terms-body-text">
        While using our Service, we may ask you to provide us with certain
        personally identifiable information that can be used to contact or
        identify you ("Personal Data"). Personally identifiable information may
        include, but is not limited to:
      </p>
      <ul className="terms-list-container">
        <li className="terms-list">Email address </li>
        <li className="terms-list">First name and last name</li>
        <li className="terms-list">Phone number</li>
        <li className="terms-list">Cookies and Usage Data</li>
      </ul>
      <h4 className="terms-title">Usage Data </h4>
      <p className="terms-body-text">
        We may also collect information that your browser sends whenever you
        visit our Service or when you access the Service by or through a mobile
        device ("Usage Data").
      </p>
      <p className="terms-body-text">
        This Usage Data may include information such as your computer's Internet
        Protocol address (e.g. IP address), browser type, browser version, the
        pages of our Service that you visit, the time and date of your visit,
        the time spent on those pages, unique device identifiers and other
        diagnostic data.
      </p>
      <p className="terms-body-text">
        When you access the Service by or through a mobile device, this Usage
        Data may include information such as the type of mobile device you use,
        your mobile device unique ID, the IP address of your mobile device, your
        mobile operating system, the type of mobile Internet browser you use,
        unique device identifiers and other diagnostic data.
      </p>
      <h4 className="terms-title">Tracking &amp; Cookies Data </h4>
      <p className="terms-body-text">
        We use cookies and similar tracking technologies to track the activity
        on our Service and we hold certain information.
      </p>
      <p className="terms-body-text">
        Cookies are files with a small amount of data which may include an
        anonymous unique identifier. Cookies are sent to your browser from a
        website and stored on your device. Other tracking technologies are also
        used such as beacons, tags and scripts to collect and track information
        and to improve and analyse our Service.
      </p>
      <p className="terms-body-text">
        You can instruct your browser to refuse all cookies or to indicate when
        a cookie is being sent. However, if you do not accept cookies, you may
        not be able to use some portions of our Service.
      </p>
      <p className="terms-body-text">Examples of Cookies we use:</p>
      <ul className="terms-list-container">
        <li className="terms-list">
          Session Cookies. We use Session Cookies to operate our Service.
        </li>
        <li className="terms-list">
          Preference Cookies. We use Preference Cookies to remember your
          preferences and various settings.
        </li>
        <li className="terms-list">
          Security Cookies. We use Security Cookies for security purposes.
        </li>
      </ul>
      <h3 className="terms-title">Use of Data </h3>
      <p className="terms-body-text">
        Flors Carolina uses the collected data for various purposes:
      </p>
      <ul className="terms-list-container">
        <li className="terms-list">To provide and maintain the Service</li>
        <li className="terms-list">
          To notify you about changes to our Service
        </li>
        <li className="terms-list">
          To allow you to participate in interactive features of our Service
          when you choose to do so
        </li>
        <li className="terms-list">To provide customer care and support</li>
        <li className="terms-list">
          To provide analysis or valuable information so that we can improve the
          Service
        </li>
        <li className="terms-list">To monitor the usage of the Service</li>
        <li className="terms-list">
          To detect, prevent and address technical issues
        </li>
      </ul>
      <h3 className="terms-title">Transfer Of Data </h3>
      <p className="terms-body-text">
        Your information, including Personal Data, may be transferred to - and
        maintained on - computers located outside of your state, province,
        country or other governmental jurisdiction where the data protection
        laws may differ than those from your jurisdiction.
      </p>
      <p className="terms-body-text">
        If you are located outside Spain and choose to provide information to
        us, please note that we transfer the data, including Personal Data, to
        Spain and process it there.
      </p>
      <p className="terms-body-text">
        Your consent to this Privacy Policy followed by your submission of such
        information represents your agreement to that transfer.
      </p>
      <p className="terms-body-text">
        Flors Carolina will take all steps reasonably necessary to ensure that
        your data is treated securely and in accordance with this Privacy Policy
        and no transfer of your Personal Data will take place to an organization
        or a country unless there are adequate controls in place including the
        security of your data and other personal information.
      </p>
      <h3 className="terms-title">Disclosure Of Data </h3>
      <h4 className="terms-title">Legal Requirements</h4>
      <p className="terms-body-text">
        Flors Carolina may disclose your Personal Data in the good faith belief
        that such action is necessary to:
      </p>
      <ul className="terms-list-container">
        <li className="terms-list">To comply with a legal obligation</li>
        <li className="terms-list">
          To protect and defend the rights or property of Flors Carolina
        </li>
        <li className="terms-list">
          To prevent or investigate possible wrongdoing in connection with the
          Service
        </li>
        <li className="terms-list">
          To protect the personal safety of users of the Service or the public
        </li>
        <li className="terms-list">To protect against legal liability</li>
      </ul>
      <p className="terms-body-text">
        As a European citizen, under GDPR, you have certain individual rights.
        You can learn more about these guides in the
        <a href="(https://termsfeed.com/blog/gdpr/#Individual_Rights_Under_the_GDPR)">
          GDPR Guide
        </a>
        .
      </p>
      <h3 className="terms-title">Security of Data </h3>
      <p className="terms-body-text">
        The security of your data is important to us but remember that no method
        of transmission over the Internet or method of electronic storage is
        100% secure. While we strive to use commercially acceptable means to
        protect your Personal Data, we cannot guarantee its absolute security.
      </p>
      <h3 className="terms-title">Service Providers </h3>
      <p className="terms-body-text">
        We may employ third party companies and individuals to facilitate our
        Service ("Service Providers"), to provide the Service on our behalf, to
        perform Service-related services or to assist us in analyzing how our
        Service is used.
      </p>
      <p className="terms-body-text">
        These third parties have access to your Personal Data only to perform
        these tasks on our behalf and are obligated not to disclose or use it
        for any other purpose.
      </p>
      <h3 className="terms-title">Links to Other Sites </h3>
      <p className="terms-body-text">
        Our Service may contain links to other sites that are not operated by
        us. If you click a third party link, you will be directed to that third
        party's site. We strongly advise you to review the Privacy Policy of
        every site you visit.
      </p>
      <p className="terms-body-text">
        We have no control over and assume no responsibility for the content,
        privacy policies or practices of any third party sites or services.
      </p>
      <h3 className="terms-title">Children's Privacy </h3>
      <p className="terms-body-text">
        Our Service does not address anyone under the age of 18 ("Children").
      </p>
      <p className="terms-body-text">
        We do not knowingly collect personally identifiable information from
        anyone under the age of 18. If you are a parent or guardian and you are
        aware that your Child has provided us with Personal Data, please contact
        us. If we become aware that we have collected Personal Data from
        children without verification of parental consent, we take steps to
        remove that information from our servers.
      </p>
      <h3 className="terms-title">Changes to This Privacy Policy </h3>
      <p className="terms-body-text">
        We may update our Privacy Policy from time to time. We will notify you
        of any changes by posting the new Privacy Policy on this page.
      </p>
      <p className="terms-body-text">
        We will let you know via email and/or a prominent notice on our Service,
        prior to the change becoming effective and update the "effective date"
        at the top of this Privacy Policy.
      </p>
      <p className="terms-body-text">
        You are advised to review this Privacy Policy periodically for any
        changes. Changes to this Privacy Policy are effective when they are
        posted on this page.
      </p>
      <h3 className="terms-title">Contact Us </h3>
      <p className="terms-body-text">
        If you have any questions about this Privacy Policy, please contact us,
        by visiting this page on our website:
      </p>
      <p id="link">
        <a href="http://www.florscarolina.com/contact">
          http://www.florscarolina.com/contact
        </a>
      </p>
    </div>
  );
}
